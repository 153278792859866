import React from 'react'

import SplitPane from 'react-split-pane-v2'
import RDChatBotify from './Shared/Wrappers/RDChatBotify/RDChatBotify'
import { TopPaneComponent } from './TopPane/TopPaneComponent'
import { AppState, ClauseList } from './AppHelpers'

interface AddInBodyProps {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
  clauseList: ClauseList[]
}

const AddInBody = (props: AddInBodyProps) => {
  const { state, clauseList } = props
  return (
    <SplitPane split={'horizontal'} allowResize>
      <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />
      <RDChatBotify sessionID='aasdfgd78' token={state?.authToken}></RDChatBotify>
    </SplitPane>
  )
}

export default AddInBody
