import React, { useEffect } from 'react'
import { RDList } from '../Shared/Wrappers/RDList'
import { getHeadersFromDoc, getRiskAnalysisfromDocument, HeaderListInterface } from './TopPaneHelpers'
import { ClauseList } from '../AppHelpers'
import { RDGrid } from '../Shared/Wrappers/RDGrid'
import { CircularProgress } from '@mui/material'

export interface TopPaneComponentProps {
  token: string
  filter: string
  clauseList: ClauseList[]
}

export const TopPaneComponent = (props: TopPaneComponentProps) => {
  const { token, filter, clauseList } = props
  const [headerList, setHeaderList] = React.useState<HeaderListInterface[]>([])
  const [filteredHeaderList, setFilteredHeaderList] = React.useState<HeaderListInterface[]>([])
  const [completedRiskAnalysis, setCompletedRiskAnalysis] = React.useState<boolean>(false)

  useEffect(() => {
    getHeadersFromDoc(setHeaderList, clauseList, token)
  }, [clauseList])

  useEffect(() => {
    if (headerList?.length > 0) {
      const filteredHeaderList = JSON.parse(JSON.stringify(headerList))
      if (!completedRiskAnalysis) {
        setFilteredHeaderList(filteredHeaderList)
        getRiskAnalysisfromDocument(
          JSON.parse(JSON.stringify(headerList)),
          setHeaderList,
          setCompletedRiskAnalysis,
          token
        )
      } else {
        if (filter?.length > 0) {
          setFilteredHeaderList(
            filteredHeaderList.filter((header) => header.name.toLowerCase().includes(filter.toLowerCase()))
          )
        } else {
          setFilteredHeaderList(filteredHeaderList)
        }
      }
    }
  }, [headerList])

  useEffect(() => {
    if (completedRiskAnalysis) {
      const filteredHeaderList = JSON.parse(JSON.stringify(headerList))
      if (filter?.length > 0) {
        setFilteredHeaderList(
          filteredHeaderList.filter((header) => header.name.toLowerCase().includes(filter.toLowerCase()))
        )
      } else {
        setFilteredHeaderList(filteredHeaderList)
      }
    }
  }, [filter])

  return (
    <RDGrid
      style={{
        overflowY: 'scroll',
        height: '100%',
        fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
        fontSize: '16px',
      }}>
      {filteredHeaderList?.length === 0 && (
        <RDGrid container justifyContent={'center'} style={{ paddingTop: '35%', height: '100%' }}>
          <RDGrid>
            <RDGrid>
              <CircularProgress />
            </RDGrid>
          </RDGrid>
          <RDGrid>
            <RDGrid>
              <RDGrid sx={{ padding: 6 }}>{`Analyzing Document`}</RDGrid>
            </RDGrid>
          </RDGrid>
        </RDGrid>
      )}
      {filteredHeaderList?.length > 0 && <RDList list={filteredHeaderList} />}
    </RDGrid>
  )
}
